<template>
  <router-link
    :to="competition.event_type === 'App\\Models\\Event' ? { name: 'streamed-event', params: { 'id': competition.event_id } } : { name: 'verdict', params: { id } }"
    class="competition-component">
    <div class='competition-component__header'>
      <h2 class='competition-component__title'>Competition by</h2>
      <Icon v-if="competition.kind_sport" :type="`sport-${competition.kind_sport.name.toLowerCase()}`" :size="24" />
    </div>

    <div v-if="competition.entity === 'tournament'" class="title">
      {{ competition.name }}
    </div>

    <template v-if="competition.entity === 'tournament' || competition.entity === 'event'">
      <Competitors :competitors="competition.competitors" :organizer="competition.organizer" :competition="competition" />
    </template>
    <template v-else>
      <div v-if="isDuel" class="versus">
        <DuelBlock :competitors="sortedCompetitors" />
      </div>

      <Competitors v-else :competitors="sortedCompetitors" :organizer="organizer" :competition="competition" />
    </template>

    <div class="date">
      <div class="event-date">
        <span> {{ date }} </span>
      </div>
    </div>

    <div class="details-wrap top-separator">
      <div v-if="competition.kind_sport.name === 'Swimming'" class="details">
        <div class="detail">
          <span class="name"> {{ $t('details.gender') }} </span>
          <span class="value"> {{ this.gender_participants || 'Mixed' }} </span>
        </div>
        <div class="detail" v-for="(value, name) in details" :key="name">
          <span class="name"> {{ $t('details.' + name) }} </span>
          <span class="value"> {{ value }} </span>
        </div>
      </div>
    </div>


    <div v-if="competition.kind_sport.name === 'Basketball'" class="details">
      <span class="name"> Discipline </span>
      <span class="value"> Around the world </span>
    </div>

    <router-link v-if="competition.entity === 'tournament'" class="video"
      :to="{ name: 'streamed-tournament', params: { id: competition.id } }"
      :style="{ backgroundImage: 'url(https://competa-b.pandateam.net.ua/storage/competitions/93/users/49/photo_2022-01-24_18-31-56.jpg)' }">
      <Icon :size="48" type="play" />
    </router-link>
    <router-link v-else-if="competition.entity === 'event'" class="video"
      :to="{ name: 'streamed-event', params: { id: competition.id } }"
      :style="{ backgroundImage: 'url(' + encodeURI(videoPreview) + ')' }">
      <Icon :size="48" type="play" />
    </router-link>
    <!--    <router-link v-else class="video" :to="{ name: 'verdict', params: { id } }" :style="{ backgroundImage: 'url(' + encodeURI(videoPreview) + ')' }">-->
    <!--      <Icon :size="48" type="play"/>-->
    <!--    </router-link>-->

    <div class="details-wrap top-separator" v-if="competition.kind_sport.name === 'Basketball'">
      <div class="details">
        <span class="name"> Discipline </span>
        <span class="value"> Around the world </span>
      </div>
    </div>

    <div class='competition-component__footer top-separator'>
      <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />

      <CommonButton :isPrevent="false" icon-size="20" icon-before="play-circle" variant="inline" color="info"
        caption="View event" />
    </div>

    <popup :open='isShowVerdictShare' @close='toggleVerdictShare'>
      <div class='competition__share'>
        <p v-if='linkWasCopied' class='competition__copied'>{{ $t('competition.copyPopup.success') }}</p>
        <button @click='copyVerdict'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
              fill="#7B8B97" />
          </svg>
          <span v-if="competition.entity === 'tournament' || 'event'">Copy Link to this Tournament</span>
          <span v-else>Copy Link to this event</span>
        </button>
      </div>
    </popup>

  </router-link>
</template>

<script>
import moment from 'moment-timezone'
import Icon from '@/components/Icon'
import CommonButton from '@/components/UI/CommonButton'
import Competitors from '@/components/Competitions/Competitors'
import DuelBlock from '@/components/Competitions/Competitor/DuelBlock'
import competition from '@/mixins/competition'
import { copyTextToClipboard } from '@/helpers'
import Popup from '@/components/Popup'

export default {
  name: 'Verdict',
  mixins: [
    competition
  ],
  props: {
    competition: {
      type: Object,
      required: true
    }
  },
  components: {
    Icon,
    Competitors,
    Popup,
    DuelBlock,
    CommonButton
  },
  data() {
    return {
      isShowVerdictShare: false,
      sharedVerdict: null,
      linkWasCopied: false
    }
  },
  computed: {
    sportIcon() {
      return 'ico-' + this.sport
    },
    date() {
      const date = this.competition?.event_at

      if (!date) {
        return null
      }

      return moment(this.competition.event_at).format('DD MMM YYYY [at] hh:mm A')
    },
    startDate() {
      const date = this.competition?.start_at

      if (!date) {
        return null
      }

      return moment(date).format('DD MMM YYYY')
    },
    endDate() {
      const date = this.competition?.start_at

      if (!date) {
        return null
      }

      return moment(date).format('DD MMM YYYY')
    },
    sortedCompetitors() {
      const getPlace = (competitor) => {
        const marker = this.competition?.markers?.find(({ user_id }) => {
          return user_id === competitor.id
        })

        return typeof marker?.place === 'number' ? marker.place : Infinity
      }

      const competitors = []

      for (let ch of this.competition.challenges) {
        if (ch.status !== 'Waiting' && ch.status !== 'Rejected') {
          competitors.push(ch.opponent)
        }
      }
      const initiator = this.competition.challenges[0].initiator
      if (!competitors.find(el => el.id === initiator.id)) {
        competitors.push(initiator)
      }

      return competitors.sort((a, b) => {
        return getPlace(a) - getPlace(b) || 0
      })
    },
    comments() {
      return '568'
    },
    views() {
      return '25.6k'
    },
    videoLenght() {
      return '00:05:15'
    },
    isDuel() {
      return this.sortedCompetitors.length === 2;
    }
  },
  methods: {
    copyVerdict() {
      this.linkWasCopied = true
      copyTextToClipboard(`${window.location.origin}/verdict/${this.competition.id}`)
    },
    toggleVerdictShare() {
      this.linkWasCopied = false
      this.isShowVerdictShare = !this.isShowVerdictShare
    },
    share() {
      this.toggleVerdictShare()
    }
  }
}
</script>

<style scoped>
.title {
  padding: 16px 16px 0;
  margin-bottom: -16px;
  color: #fff;
  font-size: 12px;
}

.verdict-component__share {
  color: #d8d8d8;
}

.competition__share {
  padding: 30px 0;
  color: #D8D8D8;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.competition__share>button {
  display: flex;
  align-items: center;
  width: 100%;
}

.competition__share>button>span {
  margin-left: 17px;
}

.competition__copied {
  position: absolute;
  bottom: calc(100% - 25px);
}

.competition-component .details-wrap .details {
  padding: 0;
  background-color: transparent;
}

.competition-component .details-wrap .details .detail .name {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #7B8B97;
}

.competition-component .details-wrap .details .detail .value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EEEEEE;
}

.top-separator:before {
  content: "";
  border-top: 1px solid #2F354D;
  width: calc(100% + 32px);
  margin: 12px -16px;
  display: block;
}

.date {
  padding-top: 24px
}
</style>
